import feathers from './feathers';

export default {
    authenticate: async () => {
        let token = await feathers.passport.getJWT();

        let authenticated = await feathers.authenticate({
            strategy: 'jwt',
            accessToken: token
        });
        let payload = await feathers.passport.verifyJWT(
            authenticated.accessToken
        );
        let user = await feathers.service('users-sql').get(payload.userId);
        user.role = user.roleObject.RoleName;

        feathers.set('user', user);
    },
    login: async (email, password) => {
        await feathers.authenticate({
            strategy: 'local',
            UserName: email,
            Password: password
        });
    },
    logout: async () => {
        await feathers.logout();
        feathers.set('user', {});
    }
};
